import React, { Suspense } from 'react'
import Routers from './router'
import './App.scss'
import { Provider } from 'react-redux';
import store from './store'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LogoSimple from './assets/images/moda_logo_simple.png';
import ScrollToTop from './components/ScrollToTopButton';

function App() {

    const Loader = () => {
      return (
				<div className="d-flex w-100 h-100 justify-content-center align-items-center min-height-700">
				<img src={LogoSimple} height={"100"} alt="Moda Logo" className="logo-simple" />
			</div>
      );
    };

  return (
   <React.Fragment>
		  <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
    <Provider store={store}>
    <Suspense fallback={Loader()}>
       <Routers />
     </Suspense>
    </Provider>
		<ScrollToTop />
   </React.Fragment>
  )
}

export default App