

const initialState = {
    data: {
      data: [],
      page: 1,
      pageSize: 10,
      totalCount: 0,
      total_pages: 0
    },
    loading: false,
    error: null,
    selectedRows: [],
    payload: {}
  };
  
  export const queryPageByIdRequest = (namespace) => (state = initialState, action) => {
    switch (action.type) {
      case `${namespace}/QUERY_PAGE_BY_ID_REQUEST_REQUEST`: {
        return { ...state, data: { ...state.data,  intervalLoading: action.intervalLoading }, error: null, loading: true };
      }
      case `${namespace}/QUERY_PAGE_BY_ID_REQUEST_SUCCESS`: {
        return { ...state, data: { ...state.data, data: action?.data?.data, page: action?.data?.page, pageSize: state.data.pageSize, totalCount: action?.data?.row_count, intervalLoading: action.intervalLoading }, loading: false,  };
      }
      case `${namespace}/QUERY_PAGE_BY_ID_REQUEST_FAILURE`: {
        return { ...state, data: { ...state.data, data: [], page: 1, pageSize: state.data.pageSize, totalCount: 0, loading: false, intervalLoading: false  }, error: action.error };
      }
      case `${namespace}/QUERY_PAGE_BY_ID_REQUEST_SET_PAGE`: {
        return { ...state, data: { ...state.data, page: action?.data?.page } }
      }
      case `${namespace}/QUERY_PAGE_BY_ID_REQUEST_RESET_PAGE`: {
        return { ...state, data: { ...state.data, page: 1 } }
      }
      case `${namespace}/QUERY_PAGE_BY_ID_REQUEST_SET_PAGE_AND_PAGE_SIZE`: {
        return { ...state, data: { ...state.data?.data, page: action?.data?.page, pageSize: action?.data?.pageSize } }
      }
      case `${namespace}/QUERY_PAGE_BY_ID_REQUEST_RESET_PAGE_SIZE`: {
        return { ...state, data: { ...state.data, pageSize: 10 } }
      }
      case `${namespace}/QUERY_PAGE_BY_ID_REQUEST_SET_PAGE_SIZE`: {
        return { ...state, data: { ...state.data, page: 1, pageSize: action?.data?.pageSize } }
      }
      case `${namespace}/QUERY_PAGE_BY_ID_REQUEST_SET_PAYLOAD`: {
        return { ...state, data: { ...state.data, page: 1, pageSize: state.data.pageSize }, payload: action.data };
      }
      case `${namespace}/QUERY_PAGE_BY_ID_REQUEST_RESET_PAYLOAD`: {
        return initialState;
      }
      case `${namespace}/QUERY_PAGE_BY_ID_REQUEST_RESET`: {
        return initialState
      }
      default:
        return state;
    }
  };
  