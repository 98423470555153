const initialState = {
  loading: false,
  data: {
    data: [],
    page_number: 1,
    page_size: 10,
    total_count: 0,
    total_pages: 1,
  },
  error: null,
};

const newsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'QUERY_PAGE_INFINITY_REQUEST':
      return { ...state, loading: true };

    case 'APPEND_PAGE_DATA':
      return {
        ...state,
        loading: false,
        data: action.payload.newData,
      };

    case 'UPDATE_NEWS_IN_STORE': {
      const { updatedNews } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          data: state.data.data.map((newsItem) =>
            newsItem.id === updatedNews.id ? updatedNews : newsItem
          ),
        },
      };
    }
		case 'RESET_PAGE_DATA':
      return {
        ...state,
        data: initialState.data,
      };

			case 'REMOVE_NEWS':
				return {
					...state,
					data: {
						...state.data,
						...action.payload.updatedState,
					},
				};

    case 'QUERY_PAGE_INFINITY_FAILURE':
      return { ...state, loading: false, error: action.payload.error };

    default:
      return state;
  }
};

export default newsReducer;
